module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tübingen Hilft Ukraine","short_name":"tuehilftua","description":"'Tübingen Hilft Ukraine' ist eine Initiative, die humanitäre Hilfe in Tübingen sammelt und in Zusammenarbeit mit ukrainischen Logistikunternehmen direkt in die Ukraine schickt.","start_url":"/","lang":"de","display":"standalone","background_color":"#eeeeee","theme_color":"#0057b7","icon":"src/assets/images/favicon.png","localize":[{"start_url":"/en/","lang":"en"},{"start_url":"/ua/","lang":"ua"},{"start_url":"/ru/","lang":"ru"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a9a719db79cc31cc98f7543b95c6b69e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["de","en","ua","ru"],"defaultLanguage":"de","siteUrl":"https://tuebingen-hilft-ukraine.de","i18nextOptions":{"fallbackLng":"de","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
